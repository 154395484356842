<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-row align="start" class="ml-2">
            <v-card-title>Kommentar</v-card-title>
            <v-col class="d-flex justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="dialogAddNewComment = true"
                    color="success"
                    fab
                    small
                    dark
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-comment-plus</v-icon>
                  </v-btn>
                </template>
                <span>Neue Kommentar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row align="start" class="ml-2">
            <v-spacer />
            <v-col class="d-flex" cols="11" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="comments"
            item-key="name"
            class="elevation-1 table-one"
            multi-sort
          >
            <template v-slot:item.name="{ item }">
              <div class="d-flex align-center">
                <v-avatar class="mr-2" size="26" dark>
                  <img :src="item.img" alt="" />
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.date="{ item }">
              {{ item.date }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="showComment(item)"
                  >
                    <v-icon>mdi-comment</v-icon>
                  </v-btn>
                </template>
                <span>Kommentar anzeigen</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
    <!--add-new-comment-->
    <v-dialog v-model="dialogAddNewComment" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Neue Kommentar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  clearable
                  clear-icon="mdi-close-circle"
                  label="Kommentar"
                  required
                  v-model="newComment.comment"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="Ls_CurrentDate"
                  label="Datum"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogAddNewComment = false"
          >
            schliessen
          </v-btn>
          <v-btn color="green darken-1" text @click="addNewComment">
            Speicheren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end--add-new-comment-->
    <!--view-comment-->
    <v-dialog v-model="dialogViewComment" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Kommentar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  rows="1"
                  readonly
                  required
                  v-model="viewComment.comment"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="viewComment.date"
                  label="Datum"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogViewComment = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end--view-comment-->
  </v-row>
</template>

<script>
import IconClassBar from "../../../components/base/IconClassBar.vue";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Table One",
  },
  components: {
    IconClassBar,
  },
  methods: {
    showComment(Ao_Comment) {
      this.dialogViewComment = true;
      this.viewComment.name = Ao_Comment.name;
      this.viewComment.date = Ao_Comment.date;
      this.viewComment.action = Ao_Comment.action;
      this.viewComment.comment = Ao_Comment.comment;
    },
    addNewComment() {
      this.comments.push(this.newComment);
      this.dialogAddNewComment = false;
    },
  },
  data() {
    return {
      dialogViewComment: false,
      Ls_CurrentDate: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      dialogAddNewComment: false,
      search: "",
      selected: [],
      headers: [
        {
          text: "Autor",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Datum", value: "date" },
        { text: "Kommentar", value: "action" },
      ],
      viewComment: {
        name: "",
        date: "",
        action: "",
        comment: "",
      },
      newComment: {
        img: require("@/assets/images/avatars/001-man.svg"),
        name: "Hans Muster",
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
        action: "add",
        comment: "",
      },
      comments: [
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          name: "Jhon Doe",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          name: "Medusa",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          name: "Jhon Doe two",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe two",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend two",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut two",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          name: "Medusa two",
          date: "23/02/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe three",
          date: "23/02/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend three",
          date: "23/02/2022",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut three",
          date: "23/02/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          name: "Medusa three",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          name: "Jhon Doe four",
          date: "14/04/2022",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe four",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend four",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut four",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
      ],
      subjects: ["Administration", "Wirtschaft und Gesellschaft"],
    };
  },
};
</script>
<style lang="scss" scoped>
.textarea-line-height {
}
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
